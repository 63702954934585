<template>
  <div>
    <a-modal
      v-model="modal1Visible"
      :footer="null"
      :title="null"
      :closable="false"
      centered
      :keyboard="false"
      :maskClosable="false"
      width="600"
      :afterClose="resetForm"
      ref="createForm"
    >
      <!-- 创建新的企业 -->
      <div style="width: 4.75rem;">
        <h2 class="modal1-title">创建新的企业</h2>
        <p class="modal1-content">请填写企业真实信息以便项目维护</p>
        <div>
          <a-form :form="createForm" :label-col="{ span: 5 }" @submit="handleCreateSubmit">
            <a-form-item>
              <a-input
                block
                v-decorator="[
                  'company',
                  {
                    rules: [
                      { required: true, message: '请输入企业名称' },
                      { pattern: /^[\u4e00-\u9fa5a-zA-Z]+$/, message: '只能输入汉字和英文' }
                    ]
                  }
                ]"
                placeholder="请输入企业名称"
              />
            </a-form-item>
            <a-form-item>
              <a-cascader
                :field-names="{ label: 'name', value: 'id', children: 'childRegions' }"
                :options="options"
                v-decorator="['area', { rules: [{ required: true, message: '请选择所在地区' }] }]"
                placeholder="请选择所在地区"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item>
              <div class="flex-end">
                <a-button @click="cancelCreate">
                  取消
                </a-button>
                <a-button
                  style="margin-left: 0.2rem;"
                  type="primary"
                  html-type="submit"
                  :loading="state.loginBtn"
                  :disabled="state.loginBtn"
                >
                  确定
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import templateTitle from '@/components/templateTitle.vue'
import { _getAllRegion, _createTenant } from '@/api/keepHome'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store'
import { Mentions } from 'ant-design-vue'
Vue.use(Mentions)
export default {
  props: {},
  data() {
    return {
      form: {
        name: '',
        unit: '',
        bd: ''
      },
      title: '',
      isView: false,
      formVisible: false,
      type: '',
      loading: false,
      rules: {
        carCode: [{ required: true, message: '请输入信息', trigger: 'blur' }]
      },
      // 新建==========
      createForm: this.$form.createForm(this, { name: 'createForm' }), //
      // 加入企业form
      joinForm: this.$form.createForm(this, { name: 'coordinated' }),
      options: [],
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      modal1Visible: false,
      addressName: ''
      // ===============
    }
  },
  created() {
    this.getRegion()
  },
  components: {
    templateTitle
  },
  methods: {
    // ====================
    resetForm() {
      console.log(this.$refs, 'this.$refs')
      this.createForm.resetFields()
    },
    onChange(value) {
      // console.log(value, '---------新建企业选择的地区')
      this.addressName = this.extractNames(value, this.options)
    },
    // 请求行政区划地址
    getRegion(val) {
      let params = val
      _getAllRegion(params).then(res => {
        this.options = res.data
      })
    },
    // 创建企业form
    cancelCreate() {
      this.modal1Visible = false
      // this.createForm = {}
    },
    // 提交创建企业form
    handleCreateSubmit(e) {
      e.preventDefault()

      const token = Vue.ls.get(ACCESS_TOKEN)
      console.log(token, '提交时获取当前token')

      this.state.loginBtn = true
      this.createForm.validateFields((err, values) => {
        if (!err) {
          let creatParams = {
            name: values.company,
            email: '',
            phone: '',
            regionId: values.area[values.area.length - 1],
            regionsName: this.addressName,
            linkMan: '',
            creditCode: '',
            address: '',
            remark: ''
          }
          // 创建新租户
          _createTenant(creatParams).then(res => {
            if (res.code == 200) {
              console.log('创建租户成功', res)
              this.$message.success('创建成功!')
              this.state.loginBtn = false
              this.modal1Visible = false
              window.location.reload()
            } else {
              this.$message.error(res.message)
              this.state.loginBtn = false
            }
          })
        } else {
          setTimeout(() => {
            this.state.loginBtn = false
          }, 600)
        }
      })
    },
    // 定义一个函数，传入一维数组和三维数组两个参数
    extractNames(idList, regions) {
      const nameList = []

      for (let i = 0; i < regions.length; ++i) {
        if (regions[i].id === idList[0]) {
          nameList.push(regions[i].name)
          // 第一级匹配
          for (let j = 0; j < regions[i].childRegions.length; ++j) {
            if (regions[i].childRegions[j].id === idList[1]) {
              nameList.push(regions[i].childRegions[j].name)
              // 第二级匹配
              for (let k = 0; k < regions[i].childRegions[j].childRegions.length; ++k) {
                if (regions[i].childRegions[j].childRegions[k].id === idList[2]) {
                  // 第三级匹配
                  nameList.push(regions[i].childRegions[j].childRegions[k].name)
                }
              }
            }
          }
        }
      }

      return nameList.join('/')
    },

    // ===============

    add(type) {
      this.modal1Visible = true
      return
      console.log('新增')
      this.title = '添加'
      this.type = type
      this.formVisible = true
      this.isView = false
      this.form = {}
    },
    edit(val, type) {
      console.log(val, '编辑')
      this.title = '编辑'
      this.type = type
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = false
      this.formVisible = true
    },
    view(val) {
      console.log(val, '查看')
      this.title = '查看'
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = true
      this.formVisible = true
    },
    // 拉取详细数据的方法
    detailCarManager(params) {
      _detailCarManager(params).then(res => {
        if (res.code == 200) {
          this.form = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClose(done) {
      this.$refs['form'].resetFields()
      done()
    },

    // 编辑、增加页面保存方法
    submitForm() {
      console.log(this.type, 'this.type++')
      // 添加
      if (this.type == 'add') {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.loading = true
            // ====写死测试数据====
            let testObj = {
              carCode: 'string',
              carNumber: '李四',
              carType: 'string',
              carCategory: 'string',
              carUseAddress: 'string',
              carUseTime: '2023-04-21T02:00:38.333Z',
              carReturnAddress: 'string',
              carReturnTime: '2023-04-21T02:00:38.333Z',
              carUseUserName: 'string',
              carUseHhoneNumber: '64101467166',
              carUseContactAddress: 'string',
              carHandleUserName: 'string',
              carHandlePhoneNumber: '66994427921',
              remark: 'string'
            }
            // ====写死测试数据====

            _addCarManager(testObj)
              .then(res => {
                this.loading = false
                this.formVisible = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      } else if (this.type == 'edit') {
        // 修改
        // this.loading = true
        this.$refs[form].validate(valid => {
          if (valid) {
            _editCollectorManager(this.form)
              .then(res => {
                this.formVisible = false
                this.loading = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      }
    },

    
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
.modal1-title {
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #333333;
}

.modal1-content {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #818c98;
}

.modal1-box {
  display: flex;
}

.creatCom,
.joinCom {
  width: 2.38rem;
  height: 2.14rem;
  cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    border: 1px solid #4689f6;

    img {
    }

    p {
      color: #4689f6;
    }
  }

  img {
    width: 1.6rem;
    height: 1.15rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #818c98;
    text-align: center;
  }
}

.modal2-box {
  width: 5rem;
  height: 3.8rem;
  // cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  // &:hover {
  //     border: 1px solid #4689F6;

  //     img {}

  //     p {
  //         color: #4689F6;
  //     }
  // }

  img {
    width: 4.85rem;
    height: 3rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #818c98;
    text-align: center;
  }
}

::v-deep .ant-modal-content {
  border-radius: 10px;
}

::v-deep .ant-input:placeholder-shown {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
</style>
