<template>
  <div>
    <a-modal
      v-model="modal1Visible"
      :footer="null"
      :title="null"
      :closable="false"
      centered
      :keyboard="false"
      :maskClosable="false"
      width="600"
      :afterClose="resetForm"
      ref="joinForm"
    >
      <!-- 加入企业 -->
      <div style="width: 4.75rem;">
        <h2 class="modal1-title">加入企业</h2>
        <p class="modal1-content">填写企业名称之后等待管理员审核</p>
        <div>
          <a-form :form="joinForm" :label-col="{ span: 5 }" @submit="handleJoinSubmit">
            <a-form-item>
              <!-- <a-input block v-decorator="['note', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                  placeholder="请输入企业名称" /> -->

              <a-tree-select
                show-search
                style="width: 100%"
                v-decorator="['companyid', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                placeholder="请输入企业名称"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                allow-clear
                tree-default-expand-all
                @search="onSearch"
                @select="onSelect"
                @change="handleChange"
                :search-value.sync="searchValue"
              >
                <a-tree-select-node
                  v-for="(item, index) in TenantsList"
                  :key="item.id"
                  :value="item.name"
                  :title="item.name"
                ></a-tree-select-node>
              </a-tree-select>
            </a-form-item>
            <a-form-item>
              <a-input
                :maxLength="50"
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入1-50字', max: 50, min: 1 }]
                  }
                ]"
                placeholder="请输入真实姓名"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-mentions
                v-decorator="[
                  'reason',
                  {
                    rules: [{ required: true, message: '请输入', max: 100, min: 1 }]
                  }
                ]"
                rows="3"
                placeholder="请输入申请理由"
              >
              </a-mentions>
            </a-form-item>

            <a-form-item>
              <div class="flex-end">
                <a-button @click="cancelJoin">
                  取消
                </a-button>
                <a-button
                  style="margin-left: 0.2rem;"
                  type="primary"
                  html-type="submit"
                  :loading="state.loginBtn"
                  :disabled="state.loginBtn"
                >
                  确定
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import templateTitle from '@/components/templateTitle.vue'
import { _refreshToken, _getAllRegion, _createTenant, _queryTenants, _joinTenants } from '@/api/keepHome'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store'
import { Mentions } from 'ant-design-vue'
Vue.use(Mentions)
export default {
  props: {},
  data() {
    return {
      form: {
        name: '',
        unit: '',
        bd: ''
      },
      title: '',
      isView: false,
      formVisible: false,
      type: '',
      loading: false,
      rules: {
        carCode: [{ required: true, message: '请输入信息', trigger: 'blur' }]
      },
      // 加入企业form
      joinForm: this.$form.createForm(this, { name: 'joinForm' }),
      options: [],
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      modal1Visible: false,
      // ===============加入企业
      searchValue: '',
      TenantsList: []
      //
    }
  },
  created() {},
  components: {
    templateTitle
  },
  methods: {
    resetForm() {
      console.log(this.$refs, 'this.$refs')
      this.joinForm.resetFields()
    },
    // 加入企业form
    cancelJoin() {
      this.modal1Visible = false
    },
    // 确定加入企业
    handleJoinSubmit(e) {
      e.preventDefault()

      this.state.loginBtn = true

      this.joinForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            id: this.companyid,
            reason: values.reason,
            name:values.name,
          }

          console.log(params)

          _joinTenants(params).then(res => {
            console.log(res, '加入企业-----')

            if (res.code == 200) {
              this.$message.success('操作成功!')
              this.state.loginBtn = false
              this.modal1Visible = false
            } else {
              this.$message.error(res.message)
              this.state.loginBtn = false
            }
          })
        } else {
          setTimeout(() => {
            this.state.loginBtn = false
          }, 600)
        }
      })
    },
    // ====================
    onChange(value) {
      console.log(value, '---------新建企业选择的地区')
      // this.area = value[value.length - 1]
      // console.log( this.area," this.area");
    },

    onSearch(value) {
      this.searchValue = value
      let params = { name: this.searchValue }
      _queryTenants(params).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.TenantsList = res.data
        }
      })
    },
    onSelect() {
      this.companyid = [...arguments][2].selectedNodes[0].data.key

      console.log(this.companyid, '-----this.companyid')
    },

    handleChange(value) {
      // console.log(value, "============");
    },
    // ===============

    add(type) {
      this.modal1Visible = true
      return
      console.log('新增')
      this.title = '添加'
      this.type = type
      this.formVisible = true
      this.isView = false
      this.form = {}
    },
    edit(val, type) {
      console.log(val, '编辑')
      this.title = '编辑'
      this.type = type
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = false
      this.formVisible = true
    },
    view(val) {
      console.log(val, '查看')
      this.title = '查看'
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = true
      this.formVisible = true
    },
    // 拉取详细数据的方法
    detailCarManager(params) {
      _detailCarManager(params).then(res => {
        if (res.code == 200) {
          this.form = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClose(done) {
      this.$refs['form'].resetFields()
      done()
    },

    // 编辑、增加页面保存方法
    submitForm() {
      console.log(this.type, 'this.type++')
      // 添加
      if (this.type == 'add') {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.loading = true
            // ====写死测试数据====
            let testObj = {
              carCode: 'string',
              carNumber: '李四',
              carType: 'string',
              carCategory: 'string',
              carUseAddress: 'string',
              carUseTime: '2023-04-21T02:00:38.333Z',
              carReturnAddress: 'string',
              carReturnTime: '2023-04-21T02:00:38.333Z',
              carUseUserName: 'string',
              carUseHhoneNumber: '64101467166',
              carUseContactAddress: 'string',
              carHandleUserName: 'string',
              carHandlePhoneNumber: '66994427921',
              remark: 'string'
            }
            // ====写死测试数据====

            _addCarManager(testObj)
              .then(res => {
                this.loading = false
                this.formVisible = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      } else if (this.type == 'edit') {
        // 修改
        // this.loading = true
        this.$refs[form].validate(valid => {
          if (valid) {
            _editCollectorManager(this.form)
              .then(res => {
                this.formVisible = false
                this.loading = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      }
    },

    
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
.modal1-title {
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #333333;
}

.modal1-content {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #818c98;
}

.modal1-box {
  display: flex;
}

.creatCom,
.joinCom {
  width: 2.38rem;
  height: 2.14rem;
  cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    border: 1px solid #4689f6;

    img {
    }

    p {
      color: #4689f6;
    }
  }

  img {
    width: 1.6rem;
    height: 1.15rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #818c98;
    text-align: center;
  }
}

.modal2-box {
  width: 5rem;
  height: 3.8rem;
  // cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  // &:hover {
  //     border: 1px solid #4689F6;

  //     img {}

  //     p {
  //         color: #4689F6;
  //     }
  // }

  img {
    width: 4.85rem;
    height: 3rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #818c98;
    text-align: center;
  }
}

::v-deep .ant-modal-content {
  border-radius: 10px;
}

// ::v-deep .ant-input:placeholder-shown {
//   border: 0;
//   border-bottom: 1px solid #d9d9d9;
// }
</style>
