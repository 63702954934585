<template>
  <div class="container">
    <templateTitle title="我的企业"></templateTitle>
    <!-- 搜索筛选 -->
    <el-form :inline="true" class="user-search flex-between">
      <div>
        <el-form-item>
          <el-button v-if="$store.state.user.admintype == 2" size="small" @click="createCom()">创建企业</el-button>
          <el-button size="small" type="primary" @click="joinCom()">加入企业</el-button>
          <el-button size="small" type="primary" icon="el-icon-refresh" @click="refreshPage"></el-button>
          <el-button size="small" type="text" @click="recordCom()">我的申请记录</el-button>
        </el-form-item>
      </div>
    </el-form>
    <!-- 内容区域 -->
    <div class="content">
      <div v-if="listData.length > 0">
        <div class="project" :class="{ 'current-Com': item.id == tenantId }" v-for="item in listData" :key="item.id">
          <div class="project-box">
            <div class="project-title flex-between">
              <div class="left">
                <div>{{ item.name }}</div>
                <button class="project-status">施工</button>
              </div>
              <div class="right">
                <el-button size="small" type="primary" plain @click="handleChange(item)" v-if="item.id !== tenantId"
                  >切换企业</el-button
                >
                <el-button
                  v-if="item.createdUserId == userId && item.id == tenantId"
                  size="small"
                  type="warning"
                  plain
                  @click="handleDissolution(item)"
                  >解散企业</el-button
                >
                <el-button
                  v-if="item.createdUserId !== userId"
                  size="small"
                  type="danger"
                  plain
                  @click="handleQuit(item)"
                  >退出企业</el-button
                >
              </div>
            </div>
            <div class="project-content">
              {{ item.id }}
            </div>
            <div class="project-other">
              <div class="classification" v-if="item.regionsName">{{ item.regionsName }}</div>
              <!-- <div class="classification">分类:水利水电工程</div>
            <div class="address">地址:湖南省长沙市岳麓区</div>
            <div class="time">计划工期:2020.08.37至2023.04.24</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="project" v-else style="color: #ccc;">暂无数据</div>
    </div>

    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <createFormDetails ref="create"></createFormDetails>
    <joinFormDetails ref="join"></joinFormDetails>
    <recordFormDetails ref="record"></recordFormDetails>

    <el-dialog
      title="解散企业"
      :visible.sync="dialogVisible"
      width="18%"
      :destroy-on-close="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <el-form class="user-layout-login ruleForm" :model="form" :rules="rules" ref="form">
        <el-form-item prop="mobile">
          <el-input
            v-model="form.mobile"
            :disabled="true"
            :maxlength="11"
            size="large"
            type="text"
            class="inputStyle"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item prop="verifyCode">
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <el-input
              v-model="form.verifyCode"
              :maxlength="4"
              size="large"
              style="width: 60%;"
              class="inputStyle"
              type="text"
              autocomplete="off"
              placeholder="验证码"
            ></el-input>
            <img
              :src="'data:image/jpg;base64,' + code.base64Image"
              alt=""
              class="codeImg"
              style="width: 1.3rem;height: 0.5rem;cursor: pointer;border-radius: 5px;"
              @click="getCode()"
            />
          </div>
        </el-form-item>

        <el-form-item prop="captcha">
          <el-input
            class="inputStyle"
            v-model="form.captcha"
            :maxlength="6"
            size="large"
            type="text"
            placeholder="请输入验证码"
          >
            <template #suffix>
              <el-button type="text" :disabled="!showButton" @click="handleFetchCode">
                {{ showButton ? '获取验证码' : `(${countDown}) 秒后重试` }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="btnClose()">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import templateTitle from '@/components/templateTitle.vue'
import createFormDetails from './components/createFormDetails.vue'
import joinFormDetails from './components/joinFormDetails.vue'
import recordFormDetails from './components/recordFormDetails.vue'
import Pagination from '@/components/Pagination'
import { _systenantMypage, _quitTenants, _dissolutionTenants, _refreshToken } from '@/api/keepHome'
import { _createImageVerify, _verifyPhoneVerify } from '@/api/modular/system/loginManage'
import { debounce } from '@/utils/index'
import Vue from 'vue'
import { ACCESS_TOKEN, ALL_APPS_MENU } from '@/store/mutation-types'
import store from '@/store'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'myCompany',

  components: {
    templateTitle,
    createFormDetails,
    joinFormDetails,
    recordFormDetails,
    Pagination
  },
  data() {
    return {
      hasImg: true, //判断项目是否上传图片
      listData: [],
      userId: null, // 拉取用户id
      tenantId: null, // 登录存储的公司id
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      dialogVisible: false,
      phone: null,
      form: {
        mobile: this.$store.state.user.info.phone,
        verifyCode: '',
        captcha: ''
      },
      code: '',
      rules: {
        mobile: [{ required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }],
        verifyCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        captcha: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
      },
      countDown: 60,
      showButton: true,
      dissolutionItem: {}, // 解散企业
      quitItem: {} // 退出企业
    }
  },
  created() {
    this.systenantMypage()
    // this.form.mobile = this.$store.state.user.info.phone
    this.userId = this.$store.state.user.info.id
    this.tenantId = this.$store.state.user.info.tenantId
  },
  mounted() {
    console.log(this.tenantId, '拉取到的用户tenantId')
  },

  methods: {
    ...mapActions(['Logout']),
    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.systenantMypage()
    },
    refreshPage() {
      window.location.reload()
    },
    // 获取企业信息
    systenantMypage() {
      const { currentPage, pageSize, total } = this.pageparm
      let params = {
        PageNo: currentPage,
        PageSize: pageSize
      }
      _systenantMypage(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows
          store.commit('SET_COMLIST', res.data.rows) //更新顶部下拉框
        } else {
        }
      })
    },
    // 切换企业
    handleChange(item) {
      let params = {}
      if (item.projectId) {
        params.tenantId = item.id
        params.projectId = item.projectId
      } else {
        params.tenantId = item.id
      }
      // 刷新token
      _refreshToken(params).then(res => {
        if (res.code == 200) {
          Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
          store.commit('SET_TOKEN', res.data.accessToken)
          this.systenantMypage()
          Vue.ls.remove(ALL_APPS_MENU) //清除菜单缓存
          window.location.reload()
        } else {
          this.$message.error(res.message + '，请刷新页面！')
        }
      })
    },
    // 解散企业
    handleDissolution(item) {
      // this.dissolutionItem = item
      // this.submitForm()
      // return
      this.$confirm('解散企业将清空该企业的所有相关信息,确定操作?', '重要提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.getCode()
          this.dissolutionItem = item
          this.dialogVisible = true
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 退出企业
    handleQuit(item) {
      this.$confirm('确定要退出当前企业?', '重要提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.quitItem = item
          const token = Vue.ls.get(ACCESS_TOKEN)
          const id = this.quitItem.id
          const params = { id: id }
          _quitTenants(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              let params2 // 刷新token参数
              let filteredArr = this.listData.filter(item => item.id !== id) // 过滤id后的数组
              if (filteredArr.length == 0) {
                this.handleLogout()
              } else if (this.tenantId == id && filteredArr.length > 0) {
                // 刷新token
                params2 = {
                  tenantId: filteredArr[0].id
                }
                if (filteredArr[0].projectId) {
                  params2.projectId = filteredArr[0].projectId
                }
              } else if (this.tenantId !== id && filteredArr.length > 0) {
                params2 = {
                  tenantId: this.tenantId
                }
                if (this.quitItem.projectId) {
                  params2.projectId = this.quitItem.projectId
                }
              }
              _refreshToken(params2).then(res => {
                if (res.code == 200) {
                  Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
                  store.commit('SET_TOKEN', res.data.accessToken)
                  this.systenantMypage()
                  Vue.ls.remove(ALL_APPS_MENU) //清除菜单缓存
                  window.location.reload()
                } else {
                  this.$message.error(res.message + '，请刷新页面！')
                }
              })
            } else {
              this.$message({
                type: 'info',
                message: res.message + '，请刷新页面！'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 当企业为空登出
    handleLogout() {
      return this.Logout({})
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 16)
        })
        .catch(err => {
          this.$message.error({
            title: '错误',
            description: err.message
          })
        })
    },
    createCom() {
      this.$refs.create.add()
    },
    joinCom() {
      this.$refs.join.add()
    },
    recordCom() {
      this.$refs.record.add()
    },
    handleClose() {
      this.btnClose()
    },
    getCode() {
      const params = {
        createCodeParam: {
          characterCount: 4,
          type: 1
        },
        fontSize: 16,
        verifyCodeType: 1
      }
      _createImageVerify(params).then(res => {
        // 获取成功
        if (res.code == 200) {
          this.code = res.data
        } else {
          this.$message.error('获取验证码失败')
        }
      })
    },
    handleFetchCode: debounce(function() {
      this.$refs.form.validateField('verifyCode', (err, values) => {
        if (!err) {
          const params = {
            createCodeParam: {
              characterCount: 6,
              type: 1
            },
            verifyCodeKey: this.code.key,
            verifyCode: this.form.verifyCode,
            smsType: 1,
            phone: this.form.mobile,
            verifyCodeType: 3
          }
          // return
          _verifyPhoneVerify(params).then(res => {
            this.showButton = false
            // 假设接口返回成功，开始倒计时
            if (res.code == 200) {
              this.code = { ...this.code, ...res.data }
              console.log(this.code, '===========================')
              // 接口请求完成后重置 loading 状态
              this.countDown = 60
              this.timer = setInterval(() => {
                if (this.countDown === 1) {
                  clearInterval(this.timer)
                  this.showButton = true
                }
                this.countDown -= 1
              }, 1000)

              setTimeout(() => {
                this.loading = false
              }, 1000)
            } else {
              this.$message.error(res.message)
              this.showButton = true
              clearInterval(this.timer)
            }
          })
        } else {
          this.$message.error('请先输入验证码！')
        }
        console.log(err, values)
      })
    }, 300),

    submitForm() {
      let id = this.dissolutionItem.id
      this.$refs.form.validateField(['captcha'], (err, values) => {
        console.log(err, values)
        if (!err) {
          const token = Vue.ls.get(ACCESS_TOKEN)
          // debugger
          const params = {
            id: id,
            verifyCodeKey: this.code.key,
            verifyCode: this.form.captcha,
            phone: this.form.mobile,
            verifyCodeType: 3
          }
          _dissolutionTenants(params).then(res => {
            console.log(res, '=========')
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.dialogVisible = false
              clearInterval(this.timer)
              this.countDown = 60
              this.btnClose()
              let params2 // 刷新token参数
              let filteredArr = this.listData.filter(item => item.id !== id) // 过滤id后的数组
              // debugger
              if (filteredArr.length == 0) {
                this.handleLogout()
              } else if (this.tenantId == id && filteredArr.length > 0) {
                // 刷新token
                params2 = {
                  tenantId: filteredArr[0].id
                }
                if (filteredArr[0].projectId) {
                  params2.projectId = filteredArr[0].projectId
                }
              }
              // 已更改为只能解散当前选中的公司
              // else if (this.tenantId !== id && filteredArr.length > 0) {
              //   params2 = {
              //     tenantId: this.tenantId
              //   }
              // }
              _refreshToken(params2).then(res => {
                if (res.code == 200) {
                  Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
                  store.commit('SET_TOKEN', res.data.accessToken)
                  this.systenantMypage()
                  Vue.ls.remove(ALL_APPS_MENU) //清除菜单缓存
                  window.location.reload()
                } else {
                }
              })
            } else {
              this.$message({
                type: 'info',
                message: res.message
              })
            }
          })
        }
      })
    },
    // 取消或者是确认
    btnClose() {
      this.$refs['form'].resetFields()
      this.dialogVisible = false
      this.showButton = true
      this.countDown = 60
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
@import '@/common/elementFromUI.scss';
::v-deep .el-link {
  margin-right: 0.125rem;
  font-size: 0.225rem;
}

.container {
  background: #ffffff;
}

// 新增导出
.user-search {
  height: 0.5625rem;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;

  .add-btn {
    width: 96px;
    height: 32px;
    background: #4689f6;
    color: #ffffff;
  }

  .export-btn {
    width: 96px;
    height: 32px;
    border: 1px solid #eeeeee;
    color: #818c98;
  }
}

// 内容区域
.content {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  overflow-y: auto;
  max-height: 8.5rem;
  border-radius: 0.05rem;

  .project {
    width: 100%;
    border-radius: 0.05rem;
    border: 1px solid #eeeeee;
    padding: 0.125rem 0.125rem 0.125rem 32px;
    height: 115px;
    box-sizing: border-box;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    font-family: PingFang SC-Bold, PingFang SC;

    & > .project-img {
      width: 12%;
      padding: 0.125rem;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 1rem;
      }
    }

    .project-box {
      // width: 88%;
      width: 100%;

      .project-title {
        display: flex;
        align-items: center;
        padding: 0.0625rem 0;

        .left {
          display: flex;
          align-items: center;

          & > div {
            font-size: 0.275rem;
            font-weight: bold;
            color: #212a33;
          }

          & > .project-status {
            width: 1rem;
            background: #f2fcf8;
            border: 0;
            border-radius: 0.05rem;
            font-size: 14px;
            font-weight: 500;
            color: #4dc28a;
            text-align: center;
            line-height: 0.275rem;
            margin-left: 0.1rem;
          }
        }

        .right {
          display: flex;
          align-items: center;
        }
      }

      .project-content {
        font-size: 14px;
        font-weight: 500;
        color: #818c98;
        line-height: 0.275rem;
        padding: 0.0625rem 0;
      }

      .project-other {
        width: 100%;
        display: flex;
        padding: 0.0625rem 0;

        & > div {
          margin-right: 100px;
          font-size: 12px;
          font-weight: 500;
          color: #818c98;
        }
      }
    }
  }
}
.current-Com {
  background: #fafbfd;
  border: 1px solid #4689f6 !important;
}

::v-deep .inputStyle > input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #eeeeee;
}
::v-deep .inputStyle > input:focus {
  border-color: #409eff;
}

::v-deep .el-dialog__body {
  padding: 0px 0.25rem;
}
</style>
