<template>
  <div>
    <el-drawer
      :visible.sync="formVisible"
      direction="rtl"
      size="80%"
      :close-on-press-escape="true"
      :before-close="handleClose"
    >
      <div v-if="formVisible">
        <div class="item">
          <div class="flex">
            <templateTitle title="我的申请记录"></templateTitle>
          </div>
          <el-table
            size="small"
            :data="listData"
            highlight-current-row
            style="width: 100%"
            :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
            border
            stripe
            height="550"
          >
            <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
            <el-table-column align="center" prop="tenantName" label="企业名称"> </el-table-column>
            <el-table-column align="center" prop="createdTime" label="申请时间"> </el-table-column>
            <el-table-column prop="status" width="80" label="状态">
              <template slot-scope="scope">
                <div :class="['status' + scope.row.status]">{{ statusEnum[scope.row.status] }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reason" width="500" label="申请理由"> </el-table-column>
            <!-- <el-table-column align="center" label="操作" width="220">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="fileView(scope.row)">查看</el-button>
                <el-button type="text" size="mini" @click="fileDownload(scope.row)">下载</el-button>
                <el-button type="text" size="mini" @click="fileUnload(scope.row)">转存</el-button>
                <el-button type="text" size="mini" @click="fileLog(scope.row)">查看日志</el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- 分页组件 -->
          <Pagination v-if="pageparm.total>10" v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
        <!-- <div class="item" style="background: none;">
          <el-row style="text-align: center;">
            <div v-if="!isView" class="dialog-footer">
              <el-button size="small" @click="formVisible = false">取消</el-button>
              <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm('form')"
                >确定</el-button
              >
            </div>
          </el-row>
        </div> -->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import templateTitle from '@/components/templateTitle.vue'
import { _usertenantRecordMypage } from '@/api/keepHome'
import Pagination from '@/components/Pagination'
export default {
  props: {},
  data() {
    return {
      listData: [],
      form: {},
      title: '',
      isView: false,
      formVisible: false,
      type: '',
      loading: false,
      rules: {
        carCode: [{ required: true, message: '请输入信息', trigger: 'blur' }]
      },
      statusEnum: {
        0: '待审核',
        1: '通过',
        2: '拒绝'
      },
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
    }
  },
  created() {},
  components: {
    templateTitle,
    Pagination
  },
  methods: {
    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.usertenantRecordMypage()
    },
    // 获取我的申请记录
    usertenantRecordMypage() {
      const { currentPage, pageSize, total } = this.pageparm
      let params = {
        PageNo: currentPage,
        PageSize: pageSize
      }
      _usertenantRecordMypage(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows
        } else {
        }
      })
    },
    add(type) {
      console.log('新增')
      this.title = '添加'
      this.type = type
      this.formVisible = true
      this.isView = false
      this.form = {}
      this.usertenantRecordMypage()
    },
    edit(val, type) {
      console.log(val, '编辑')
      this.title = '编辑'
      this.type = type
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = false
      this.formVisible = true
    },
    view(val) {
      console.log(val, '查看')
      this.title = '查看'
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = true
      this.formVisible = true
    },
    // 拉取详细数据的方法
    detailCarManager(params) {
      _detailCarManager(params).then(res => {
        if (res.code == 200) {
          this.form = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClose(done) {
      //   this.$refs['form'].resetFields()
      done()
    },

    // 编辑、增加页面保存方法
    submitForm() {
      console.log(this.type, 'this.type++')
      // 添加
      if (this.type == 'add') {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.loading = true
            // ====写死测试数据====
            let testObj = {
              carCode: 'string',
              carNumber: '李四',
              carType: 'string',
              carCategory: 'string',
              carUseAddress: 'string',
              carUseTime: '2023-04-21T02:00:38.333Z',
              carReturnAddress: 'string',
              carReturnTime: '2023-04-21T02:00:38.333Z',
              carUseUserName: 'string',
              carUseHhoneNumber: '64101467166',
              carUseContactAddress: 'string',
              carHandleUserName: 'string',
              carHandlePhoneNumber: '66994427921',
              remark: 'string'
            }
            // ====写死测试数据====

            _addCarManager(testObj)
              .then(res => {
                this.loading = false
                this.formVisible = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      } else if (this.type == 'edit') {
        // 修改
        // this.loading = true
        this.$refs[form].validate(valid => {
          if (valid) {
            _editCollectorManager(this.form)
              .then(res => {
                this.formVisible = false
                this.loading = false
                if (res.code == 200) {
                  this.$emit('refreshTable')
                  this.$message({
                    type: 'success',
                    message: '保存成功！'
                  })
                } else {
                  this.$message({
                    type: 'info',
                    message: res.message
                  })
                }
              })
              .catch(err => {
                this.formVisible = false
                this.loading = false
                this.$message.error('保存失败，请稍后再试！')
              })
          } else {
            return false
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
.status0 {
  color: #ff8000;
}

// .bad {
.status1 {
  color: rgb(60, 170, 56);
}

.status2 {
  color: red;
}
</style>
